<template>
    <dd>{{tween_number }} <template v-if="!hide_currency"> kr.</template></dd>
</template>

<script>

import gsap from "gsap"

export default {
    name: 'animatedNumber',
    props: {
        number: { default: 0 },
        hide_currency: {default: false},
        decimals: {default: 0},
    },
    data() {
      return {
          animated_number: 0
      }
    },
    computed: {
        tween_number() {
            return this.animated_number.toFixed(this.decimals)
        },
    },
    methods: {},
    mounted() {
        this.animated_number = this.number
    },
    watch: {
      number: function(newValue) {
        gsap.to(this.$data, { duration: 0.4, animated_number: newValue });
      }
    }
}
</script>

