<template>
<div class="screen dashboard">
 
    <h2 class="screen__title">Demo konto</h2>

    <div class="dashboard__flex">
        <div class="dashboard__flexitem">

            <div class="dashboard__flexblock">
                <TrialTimer />
            </div>

            <div class="dashboard__flexblock">
                <Map />
            </div>

        </div>

        <div class="dashboard__flexitem">
            <div class="dashboard__flexblock">
                <PricesCompact />
            </div>    

            <div class="dashboard__flexblock">
                <AwaitingJobs title="Nye bygherrer venter på svar" />
            </div>
        </div>

    </div>

</div>
</template>


<script>
import PricesCompact from "@/components/PricesCompact";
import TrialTimer from "@/components/TrialTimer";
import Map from "@/components/Map";
import AwaitingJobs from "@/components/AwaitingJobs";


export default {
   
  components: {
    PricesCompact,
    TrialTimer,
    Map, 
    AwaitingJobs
  },


  data() {
      return {
      }
  },
  
};
</script>


<style scoped lang="scss">


.dashboard {
    &__flex {
        display: flex;
        justify-content: stretch;
        gap: 2rem;

    }

    &__flexitem {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
    }

    &__flexblock {
        margin-bottom: 2rem;
    }

    @media( max-width: 1499px ) {
        &__flex {
            flex-direction: column;
        }

        &__flexitem {
            width: 100%;
        }
    }

}


</style>