<template>
    <div class="pricescompact box">

        <h3 class="pricescompact__title">Ønsker du at opgradere din samarbejdsaftale?</h3>

        <p class="pricescompact__desc">Vi laver altid aftaler, der passer til dit firmas kapacitet, kompetencer og ønsker.
        Vælg en pakke og bliv kontaktet for et uforpligtende tilbud.
        </p>

        <div class="packages">

            <div class="package package--current">
                <p class="package__title">Gratis profil</p>
                <p class="package__price">0 kr.</p>
                <button class="btn btn--disabled">Nuværende</button>
            </div>

            <div class="package">
                <div class="package__badge">Populær</div>
                <p class="package__title">Basisoptagelse</p>
                <p class="package__price">Kontakt for pris</p>
                <button class="btn btn--green" @click="trigger_contact_modal">Ring mig op</button>
            </div>

            <div class="package">
                <p class="package__title">Basisoptagelse + Plusprofil</p>
                <p class="package__price">Kontakt for pris</p>
                <button class="btn btn--green" @click="trigger_contact_modal">Ring mig op</button>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
           
        }
    },

    methods: {
        trigger_contact_modal() {
            this.$store.dispatch('contact_toggle', true)
        }
    },

    

}
</script>


<style scoped lang="scss">

    .pricescompact {
        &__desc {
            margin: 1rem 0 2rem 0;
        }
    }

    .packages {
        display: flex;
        gap: 1.5rem;
        @include mobile {
            flex-direction: column;
        }
    }

    .package {

        position: relative;
        background: $color16;
        padding: 1rem 1rem;
        padding-top: 3rem;
        border-radius: $radius;
        text-align: center;
        width: 33.333%;

        &__title {
            font-size: 0.85rem;
        }

        &__price {
            font-size: 1.3rem;
            font-weight: 600;
            color: $color1;
            margin: 1rem 0;
        }

        &__badge {
            background: $color2;
            color: white;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: $radius $radius 0 0;
            z-index: 1;
            width: 100%;
            padding: 7px;
            font-size: 0.9rem;
        }

        &--current {
            background: white;
            border: 2px dashed $muted;
        }

        @include mobile {
            width: 100%;
        }
    }

</style>