<template>

    <div class="trialtimer" v-if="current_user">

        <div class="trialtimer__text">
            <h2 class="pricescompact__title">Hej {{current_user.user_companyname}}</h2>
            <p>Velkommen til jeres demo konto. Der er eksklusiv adgang de næste <b>{{days_left}} dage</b>, hvorefter adgangen vil udløbe.</p>
        </div>

        <div class="trialtimer__anim">
           <Vue3Lottie :animationData="toemrer" :autoPlay="true" :loop="true"/>
        </div>

    </div>

</template>

<script>

import toemrer from '@/assets/lottie/toemrer.json'

export default {
    
    data() {
        return {
            //animOptions: {animationData: toemrer, autoplay: true, loop: true},
            anim: null,
            toemrer,
        }
    },

    computed: {
        current_user: function() {
            return this.$store.getters.current_user
        },
        
        days_left: function() { 
            return this.$store.getters.days_left
        }
    },

    methods: {
    },
    

}
</script>


<style scoped lang="scss">


.trialtimer { 
    width: 100%;
    background: $color16;
    position: relative;
    border-radius: $radius;
    padding: 2rem;

    &__text {
        max-width: calc(100% - 150px);
        width: 400px;
        h2 {
            margin-bottom: 1rem;
        }
    }

    &__anim {
        width: 270px;
        position: absolute;
        right: -1rem;
        bottom: 0px;
        overflow: hidden;

        > div {
            position: relative;
            bottom: -80px;
        }
    }

    @include mobile {

            padding: 1rem;

            &__text {
                width: 100%;
                max-width: 100%;
            }
            &__anim {
                display: none;
            }
    }

}
  
</style>